<template>
    <div class="members">
        <mem-header/>
        <mem-content v-if="showContent" :memberVip="memberVip" :memberTemp="memberTemp"/>
    </div>
</template>

<script>
import MemHeader from './MemHeader.vue';
import MemContent from './MemContent.vue'
export default {
    components: { MemHeader, MemContent },
    data() {
        return{
            visitId: new Date().getTime(),
            pageId: 'members',
            pageName: '会员信息',

            memberVip: {},
            memberTemp: {},
            showContent: false
        }
    },
    created() {
        if (!this.$help.token.get()) {
            return this.$help.goLogin()
        }
        this.getMemberPrice()
        this.$help.socket.send(this)
    },
    methods: {
        // 获取会员充值的价格
        getMemberPrice() {
            this.$store.commit('loading')
            this.$api.get('sys/sysRole/querySysRoleList', undefined, {'Visit-Id': this.$help.getVisitId(this.visitId)}).then(res=>{
                this.showContent = true
                if(res.data && res.data.length !== 0) {
                    res.data.forEach(ele=>{
                        if(ele.id === this.$variable.member.vip){
                            this.memberVip = ele
                        }
                        if(ele.id === this.$variable.member.temp){
                            this.memberTemp = ele
                        }
                    })
                }
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        },
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    $gray: #D5D6D8;
    .members{
        background-image: url('~@/assets/img/members-bg.png');
        background-position: center;
        background-repeat: no-repeat;
        background-attachment:fixed;
        width: 100%;
        height: 100%;
        color: #fff;
    }

</style>